(function(){

	var input = $('[data-input-file="true"]');

	input.each(function(){

		var isso = $(this);

		isso.find('input[type="file"]').on('change',function(){
			var valor = $(this).val();
			var recebedor = isso.find('.selecione');
			recebedor.text(valor);
		});

	});

})();