let ZoomProduto = (function(){
	let produtoZoom = document.getElementById('zoom-image');

	if(produtoZoom){
		let zoomJs = document.createElement('script');

		zoomJs.src = 'https://cdnjs.cloudflare.com/ajax/libs/elevatezoom/3.0.8/jquery.elevatezoom.min.js';

		document.body.appendChild(zoomJs);

		zoomJs.addEventListener('load', function(){
			if(midiaSize == 'xs' || midiaSize == 'sm'){
				$('#zoom-image').elevateZoom({
					zoomType: 'inner',
					gallery: 'gallery'
				});
			}else{
				$('#zoom-image').elevateZoom({
					gallery: 'gallery'
				});
			}

			$('#zoom-image').bind('click', function(e){
				let ez = $('#zoom-image').data('elevateZoom');

				$.fancybox(ez.getGalleryList());

				return false;
			})
		});
	}
})();